import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const customersApi = createApi({
  reducerPath: 'customers',
  tagTypes: ['Customer'],
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      query: ({ pageno, limit, search }) => `/auth/customer?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['Customer'],
    }),
    
    updateCustomer: builder.mutation({
      query: (data) => ({
        url: `auth/updatecustomer/${data._id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data.updateData,
      }),
      invalidatesTags: ['Customer'],
    }),
  }),
});

export const { useGetAllCustomersQuery, useUpdateCustomerMutation } =
  customersApi;
