import * as React from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useSettingsContext } from 'src/components/settings';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import {
  Alert,
  Badge,
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { Container, Stack, borderRadius, padding } from '@mui/system';
import { useBoolean } from 'src/hooks/use-boolean';
import TimePicker from './time-picker';
import Label from 'src/components/label';
import { useCallback } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { useSnackbar } from "src/components/snackbar";
import { format } from 'date-fns';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import { DateEnv } from '@fullcalendar/core/internal';
import { useUpdateBusinessHoursMutation, useUpdateBusinessVacationsMutation } from 'src/store/Reducer/business';

import { LoadingButton } from '@mui/lab';
import { ConfirmDialog } from 'src/components/custom-dialog';




export default function ResponsiveDatePickers() {
  const { branch } = useMockedBranch();

  const settings = useSettingsContext();
  const theme = useTheme();
  const [allDay, setSwitch] = useState(true);

  const [updateBusinessVacations] = useUpdateBusinessVacationsMutation();


  const confirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const quickOpen = useBoolean();
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [catchSelectedDate, setcatchSelectedDate] = useState([]);
  const [DateList, setDateList] = useState([]);
  const [indexValue, setIndexValue] = useState();




  console.log('highlightedDays = ', highlightedDays)
  console.log('catchSelectedDate = ', catchSelectedDate)
  console.log('DateList = ', DateList)


  const handleSubmit = (async (data) => {
    try {
      const newData = {
        vacations: DateList,
      };

      let response = await updateBusinessVacations({
        _id: branch?._id,
        vacations: newData,
      });

      console.log("Response = ", response)

      if (response.error) {
        enqueueSnackbar(response.error.data.message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } else {
        enqueueSnackbar('Update Success!');
        await new Promise((resolve) => setTimeout(resolve, 500));
        confirm.onFalse();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleFilters = useCallback((name, value, index) => {

    setDateList((prevDateList) =>
      prevDateList.map((item, idx) =>
        idx === index
          ? { ...item, [name]: value }
          : item
      )
    );
  }, []);

  const handleFilterSwitch = useCallback(
    (event, index) => {
      setIndexValue((s) => index)

      if (event.target.checked === false) {
        quickOpen.onTrue();
      }
      setDateList((prevDateList) =>
        prevDateList.map((item, idx) =>
          idx === index
            ? { ...item, allDay: event.target.checked }
            : item
        )
      );
    },
    []
  );

  const handleChange = (e) => {
    if (DateList?.includes(format(new Date(e.$d), "dd MMM yyyy"))) {
      return false;
    }
    if (highlightedDays?.includes(e.$D)) {
      const arr = highlightedDays?.filter((iter) => iter !== e.$D);
      setHighlightedDays((s) => [...arr]);
      if (catchSelectedDate.includes(format(new Date(e.$d), "dd MMM yyyy"))) {
        const arr2 = catchSelectedDate?.filter(
          (iter) => iter !== format(new Date(e.$d), "dd MMM yyyy")
        );
        setcatchSelectedDate([...arr2]);
      }
    } else {
      setHighlightedDays((s) => [...highlightedDays, e.$D]);
      setcatchSelectedDate([...catchSelectedDate, {
        allDay: true,
        date: format(new Date(e.$d), "dd MMM yyyy"),
      }]);
    }
  };

  const handleLimitOfDay = () => {
    let today = new Date();
    let numberOfDaysToAdd = 10;
    return format(
      new Date(today.setDate(today.getDate() + numberOfDaysToAdd)),
      "dd MMM yyyy"
    );
  };




  const handleOk = (e) => {
    setDateList((prevState) => {
      // Filter out the duplicates
      const newEntries = catchSelectedDate.filter(
        (newItem) => !prevState.some((existingItem) => existingItem.date === newItem.date)
      );

      console.log("newEntries = ", newEntries)
      // Map the new entries to the desired format
      const formattedEntries = newEntries.map(item => ({
        allDay: item.allDay,
        date: item.date,
        startTime: '',
        endTime: '',
      }));

      return [...prevState, ...formattedEntries];
    });

    setHighlightedDays((s) => []);
    setcatchSelectedDate((s) => []);
  };

  // const handleOk = (e) => {
  //   setDateList((prevState) => [
  //     ...prevState,
  //     ...catchSelectedDate.map(item => ({
  //       allDay: item.allDay,
  //       date: item.date,
  //       startTime: '',
  //       endTime: '',
  //     }))
  //   ]);

  //   setHighlightedDays((s) => []);
  //   setcatchSelectedDate((s) => []);
  // };

  const handleClose = () => {
    setHighlightedDays((s) => []);
    setcatchSelectedDate((s) => []);
  }

  const handleUndo = (e) => {
    setHighlightedDays((s) => []);
    setcatchSelectedDate((s) => []);
    setDateList((s) => []);
  };

  const handleDelete = (x) => {
    const arr = DateList.filter((e) => e.date !== format(new Date(x.date), "dd MMM yyyy"));
    setDateList((s) => [...arr]);
  };



  const CustomActionBar = ({ onAccept, onCancel, handleUpdate }) => {
    return (
      <Box sx={{ marginRight : '-40%', marginTop : '-2rem', marginBottom : '1rem', display : 'block', justifyContent : 'start'}}>
        <Button sx={{marginRight : '.5rem'}} variant='contained' onClick={handleClose}>Cancel</Button>
        <Button color = {'primary'} variant='contained' onClick={handleOk}>Ok</Button>
      </Box>
    );
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <TimePicker onFilters={handleFilters} index={indexValue} open={quickOpen.value} onClose={quickOpen.onFalse} />


      <Grid container sx={{ display: 'flex', justifyContent: 'center' }} spacing={3}>
        <Grid item>
          <Alert icon={<Iconify icon="solar:info-square-bold" />} severity="info">
            By default all days are available.
          </Alert>

          <Box sx={{ marginTop: '1rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                onChange={handleChange}
                maxDate={dayjs(new Date(handleLimitOfDay()))}
                minDate={dayjs(new Date())}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
                slots={{
                  actionBar: CustomActionBar,
                  day: ServerDay,
                }}
                slotProps={{
                  actionBar: {
                    onAccept: handleOk,
                    onCancel: handleClose,
                  },
                  day: {
                    highlightedDays,
                  },
                }}
                orientation="portrait"
                defaultValue={dayjs(new Date())}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item md={4.5} justifyContent={'end'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Label color="error" sx={{ fontSize: '.9rem' }}>Leave Days</Label>
            <IconButton variant="soft"
              onClick={() => handleUndo()}
            > <Iconify
                width="1.5rem"
                sx={{ cusrsor: 'pointer' }}
                icon="solar:refresh-bold"
              />

            </IconButton>
          </Box>
          <Box sx={{ marginTop: '.5rem', maxHeight: '35rem', overflow: 'scroll', scrollBehavior: 'smooth', borderRadius: '1rem', border: '1px solid #cccccc' }}> {Array.isArray(DateList) &&
            DateList
              .sort((a, b) => new Date(a) - new Date(b))
              .map((iter, index) => {
                return (
                  <Stack
                    sx={{
                      marginX: '1rem',
                      marginTop: '.5rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: '1rem',
                      borderRadius: '1rem',
                      boxShadow: 3,
                    }}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                      <Box>
                        <Typography variant="subtitle1" whiteSpace={'nowrap'} sx={{
                          marginRight: {
                            xs: '8rem'
                          }
                        }}>
                          {iter.date}
                        </Typography>
                        <Typography variant="body2" whiteSpace={'nowrap'} sx={{
                          marginRight: {
                            xs: '8rem'
                          }
                        }}>
                          {iter?.allDay ? "" : iter.startTime + " - " + iter.endTime}
                        </Typography>
                      </Box>

                      <IconButton variant="soft"
                        onClick={() => handleDelete(iter)}
                      > <Iconify
                          width="2rem"
                          sx={{ color: '#ef5350', cusrsor: 'pointer' }}
                          icon="basil:cancel-solid"
                        />

                      </IconButton>
                    </div>
                    <FormControlLabel
                      control={<Switch onChange={(event) => handleFilterSwitch(event, index)} defaultChecked={iter.allDay} name="jason" color="primary" />}
                      label="All Day"
                    />
                  </Stack>
                )
              })
          }
          </Box>
        </Grid>
      </Grid>
      <Box onClick={confirm.onTrue} display={'flex'} justifyContent={'center'}> < Button sx={{ mt: 2 }} variant="contained" color="warning" size="large">
        Save Vacations
      </Button></Box>


      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to save?"
        action={
          <LoadingButton onClick={handleSubmit} type="submit" variant="contained" color='primary'>
            Save
          </LoadingButton>
        }
      />
    </Container>
  );
}


function ServerDay(props) {

  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isSelected ? <Iconify icon="icon-park-outline:dot" color="#4caf50" /> : ""
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

