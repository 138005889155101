import React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
import { _socials } from 'src/_mock';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { Button, InputAdornment, TextField } from '@mui/material';
import EmailInboxIcon from 'src/assets/icons/email-inbox-icon';
import { useLocales } from 'src/locales';
const SOCIALS = [
  { name: 'Facebook', href: 'https://www.facebook.com/people/Timezzi/61561168651268/' },
  { name: 'Instagram', href: 'https://www.instagram.com/timezzi_com?igsh=MWsxeWpnY3o4MHNsOA%3D%3D&utm_source=qr' },
  { name: 'Linkedin', href: 'https://www.linkedin.com/company/timezzi/?viewAsMember=true' },
]
const LINKS = [
  {
    headline: 'About Timezzi',
    children: [
      { name: 'Carrers', href: '#' },
      { name: 'About us', href: paths.about },
      { name: 'Testimonial', href: paths.faqs },
      { name: 'Contact us', href: paths.contact },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Privacy Policy', href: '#' },
      { name: 'Terms & Condition', href: '#' },
      { name: 'Terms of Use', href: '#' },
    ],
  },
  {
    headline: 'Find Us',
    children: SOCIALS,
  },
];

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === '/';

  const { t } = useLocales();

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        bgcolor: 'background.default',
        color: 'white',
      }}
    >
      <Container>
        <Typography
          variant="caption"
          component="div"
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Change direction based on screen size
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Logo sx={{ mb: { xs: 2, sm: 0 } }} /> {/* Add margin on small screens */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-start' }, // Center on small screens
              mb: { xs: 2, sm: 0 }, // Add margin on small screens
            }}
          >
            <Link href="/privacy-policy" sx={{ mr: { xs: 2, sm: 5 }, color: '#6a6a6a' }} variant='h6'>
              {t('Privacy Policy')}
            </Link>
            <Link href="/tac" sx={{ color: '#6a6a6a' }} variant='h6'>
              {t('Terms & Conditions')}

            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'flex-end' }, // Align icons on small screens
            }}
          >

            {SOCIALS?.map((v) => (
              <IconButton aria-label={v.name} sx={{ mr: 1 }}>
                <Link href={v.href} target="_blank" rel="noopener" underline="none">
                  <Iconify icon={`ri:${v.name?.toLowerCase()}-fill`} width={18} />
                </Link>
              </IconButton>
            ))}
          </Box>
        </Typography>
        <Typography variant="secondary" component="div" sx={{ mb: 1, color: '#6a6a6a' }}>
        {t('© 2024 All rights reserved.')} 
        </Typography>
      </Container>
    </Box>

  );


  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //       color: 'white', // set text color to white
  //     }}
  //   >
  //     <Container>
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by
  //         <Link href="https://minimals.cc/" sx={{ color: 'white' }}>
  //           {' '}
  //           Timezzi{' '}
  //         </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        background: 'linear-gradient(to left, #1c1235, black)',
        color: 'white', // set text color to white
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={9} md={5} >
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Register with us and get more benefits and discounts with Timezzi.
            </Typography>

            <Grid container spacing={0} alignItems="center">
              <Grid item xs={2} sm={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter your email address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailInboxIcon />
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: '15px 0 0 15px',
                      backgroundColor: '#F5F5F5',
                    },
                  }}
                  sx={{
                    mt: 5,
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '0 15px 15px 0',
                    height: '100%',
                    minHeight: '53px',
                    backgroundColor: '#6C63FF',
                    '&:hover': {
                      backgroundColor: '#5a52cc',
                    },
                    mt: 5,
                  }}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                      sx={{ color: 'white' }} // set link color to white
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 10 }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  // return isHome ? simpleFooter : mainFooter;
  return simpleFooter;
}
