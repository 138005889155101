import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const preSignupBusinessApi = createApi({
  reducerPath: 'pre_signup_business',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['pre_signup_businesses'],
  endpoints: (builder) => ({
    getPreBusiness: builder.query({
      query: ({ search, pageno, limit }) =>
        `/pre_signup_business?search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providesTags: ['pre_signup_business'],
    }),
    getPreBusinessById: builder.query({
      query: (id) => `/pre_signup_business/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['pre_signup_businesses'],
    }),

    addNewPreBusiness: builder.mutation({
      query: (newService) => ({
        url: `/pre_signup_business`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newService,
      }),
      invalidatesTags: ['pre_signup_businesses'],
    }),


    updatePreBusiness: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/pre_signup_business/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['pre_signup_businesses'],
    }),
  }),
});

export const {
 useGetPreBusinessByIdQuery,useGetPreBusinessQuery,useUpdatePreBusinessMutation,useAddNewPreBusinessMutation
} = preSignupBusinessApi;
