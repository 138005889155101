import PropTypes from 'prop-types';
// @mui
import { m } from 'framer-motion';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme } from '@mui/material/styles';
import { Button, Grid, Container } from '@mui/material';
// components
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { Box, margin } from '@mui/system';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useRef } from 'react';
import { useEffect } from 'react';
import videosrc from "./Videos/video_About.mp4"
export default function MostRecommended({ subheader = '', ...other }) {

  const theme = useTheme();

  const xsUp = useResponsive('up', 'xs');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  const { currentLang, t } = useLocales();

  return (
    <Container
      component={MotionViewport}
      sx={{
        marginBottom: '5rem',
        py: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}
    >
      <Grid container columnSpacing={{ md: 3 }} alignItems="center" >

        <Grid xs={12} md={5} lg={5} alignItems="center" sx={{ pr: { md: 7 } }}>

          <m.div variants={varFade().inUp}>
            <video style={{
              // ratio : "1/1",
              // marginLeft: {
              //   lg: 0,
              //   md: '1rem',
              // },
              // width: {
              //   md: '100%',
              //   sm: '70%',
              //   xs: '10%',
              // }, borderRadius: 3

              width: '100%',
              borderRadius: '.5rem',
              objectFit: 'cover'
            }} src={videosrc} ref={videoRef} playsInline autoPlay disablePictureInPicture muted />

          </m.div>
        </Grid>


        <Grid xs={12} md={7} lg={7}>
          <m.div variants={varFade().inUp}>
            <Typography
              component="div"
              variant="h2"
              sx={{
                marginTop: {
                  lg: '0rem',
                  md: '0rem',
                  sm: '4rem',
                  xs: '4rem'
                }, mb: 0.5, textAlign: 'center'
              }}
            >
              <span style={{ color: '#7955cf', margin: '0 10px' }}>{t('Timezzi')}</span>
              {t("is on it's way")}.
            </Typography>
            <Typography
              component="div"
              variant="h3"
              sx={{ mb: 0, marginTop: '5px', textAlign: 'center' }}
            >

              <span style={{ margin: '0' }}>{t("Ultimate Lifestyle Solutions")}.</span>
            </Typography>
          </m.div>

          <m.div variants={varFade().inUp}>


            <Typography
              component="div"
              variant="p"
              marginX={0}
              textAlign={'justify'}
              sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
            >
              {t("A new company is emerging in the heart of the United Arab Emirates!")}


            </Typography>

            <Typography
              component="div"
              variant="p"
              marginX={0}
              textAlign={'justify'}
              sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
            >

              {t('At the intersection of tradition and modernity, Timezzi is set to revolutionize lifestyle industry with a few taps.')}
            </Typography>

            <Typography
              component="div"
              variant="p"
              marginX={0}
              textAlign={'justify'}
              sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
            >

              {t("Timezzi is your all-in-one scheduling solution for lifestyle services, including home service offerings. With seamless appointment management, real-time calendar synchronization, and efficient waitlist management. Streamlines operations and enhances customer satisfaction. Take advantage of our social media booking integration, attract customers with marketing tools and retain them with flexible memberships. Timezzi centralizes your business operations, allowing you to focus on providing exceptional services. Elevate your lifestyle service business to new heights.")}
            </Typography>
            <Typography
              component="div"
              variant="p"
              marginX={0}
              textAlign={'justify'}
              sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
            >
              {t("Join us on this exciting journey as we cater to the unique needs of the UAE, inspiring and engaging communities across the region. Timezzi is more than just a service - it's a lifestyle revolution. Shape the future with us today!")}
            </Typography>
          </m.div>
        </Grid>
      </Grid>
    </Container>
  );
}

MostRecommended.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};