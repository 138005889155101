import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (login) => ({
        url: `/auth/login`,
        method: 'POST',
        body: login,
      }),
    }),

    register: builder.mutation({
      query: (register) => ({
        url: `/auth/signup`,
        method: 'POST',
        // headers: { "Content-Type": "multipart/form-data" },
        body: register,
      }),
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: `/auth/resetPassword`,
        method: 'PUT',
        body: data,
      }),
    }),

    verifyEmail: builder.mutation({
      query: (data) => ({
        url: `/auth/verify`,
        method: 'POST',
        body: data,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/auth/forgotpassword`,
        method: 'PUT',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/auth/resetpassword`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useRegisterMutation,
  useChangePasswordMutation,
  useVerifyEmailMutation,
  useResetPasswordMutation,
  useLoginMutation,
} = authApi;
