import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Iconify from 'src/components/iconify';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import { Icon } from '@mui/material';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import { m } from 'framer-motion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useResponsive } from 'src/hooks/use-responsive';
import PartnerBenefits from './partner-benefits';
import { MotionViewport, varFade } from 'src/components/animate';
import { Font } from '@react-pdf/renderer';
import Image from 'src/components/image';
import { alpha, useTheme } from '@mui/material/styles';
import { useLocales } from 'src/locales';

import PartnerGif2 from "./Gif/partnerwithus.gif"
import PartnerGif1 from "./Gif/BecomePartner.gif"
import { useRef } from 'react';
import { useEffect } from 'react';
import videosrc1 from "./Videos/Become_Partner.mp4"
import videosrc2 from "./Videos/partner_With_Us.mp4"

export default function BecomePartner({ subheader = '', ...other }) {

  const { currentLang, t } = useLocales();


  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <>
      <Container component={MotionViewport} sx={{
        height: '100%',
        py: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}>

        <Grid container columnSpacing={{ md: 3 }} flexDirection={'row-reverse'} alignItems="center" >

          <Grid xs={12} md={5} lg={5} alignItems="center" sx={{ pr: { md: 7 } }}>

            <m.div variants={varFade().inUp}>
              <video style={{
                width: '100%',
                borderRadius: '.5rem',
                objectFit: 'cover'
              }} src={videosrc1} ref={videoRef} playsInline autoPlay disablePictureInPicture muted />

            </m.div>

          </Grid>


          <Grid xs={12} md={7} lg={7} sx={{
            paddingRight: {
              lg: '4rem',
              md: '4rem',
              sm: '0rem',
              xs: '0rem'
            },

            marginTop: {
              lg: '0rem',
              md: '0rem',
              sm: '2rem',
              xs: '2rem'
            }
          }}>
            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="h2"
                sx={{ mb: 1, marginTop: '0px', textAlign: 'center', fontStyle: 'italic' }}
              >
                {t('Become')}
                <span style={{ color: '#7955cf', margin: '0 10px' }}> {t('Partner')} </span>
              </Typography>
              <Typography
                component="div"
                variant="h5"
                marginX={5}
                textAlign={'center'}
                sx={{ mb: 1, marginTop: '20px' }}
              >
                {t('Transform Your Lifestyle Service Business with Timezzi')}
              </Typography>

            </m.div>

            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="p"
                marginX={0}
                textAlign={'justify'}
                sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
              >
                {t('Imagine a world where managing your lifestyle service business is effortless, efficient, and empowering. As a Timezzi partner, this vision becomes your reality. Join us on a journey to redefine how you operate, engage with customers, and grow your business.')}
              </Typography>
            </m.div>
          </Grid>
        </Grid>


        <Grid container columnSpacing={{ md: 3 }} alignItems="center" flexDirection={'row'} margin='5rem 0rem' >

          <Grid xs={12} md={5} lg={5} alignItems="center" sx={{ pr: { md: 7 } }}>

            <m.div variants={varFade().inUp}>
              <video style={{
                width: '100%',
                borderRadius: '.5rem',
                objectFit: 'cover'
              }} src={videosrc2} ref={videoRef} playsInline autoPlay disablePictureInPicture muted />

            </m.div>

          </Grid>


          <Grid xs={12} md={7} lg={7} sx={{
            paddingLeft: {
              lg: '4rem',
              md: '4rem',
              sm: '0rem',
              xs: '0rem'
            },

            marginTop: {
              lg: '0rem',
              md: '0rem',
              sm: '2rem',
              xs: '2rem'
            }
          }} >
            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="h2"
                sx={{ mb: 1, marginTop: '0px', textAlign: 'center', fontStyle: 'italic' }}
              >
                {t('Why Partner with')}
                <span style={{ color: '#7955cf', marginLeft: '0 10px' }}> {t('Us?')} </span>

              </Typography>

            </m.div>

            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="p"
                marginX={0}
                textAlign={'justify'}
                sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
              >
                {t('Effortlessly manage appointments with our intuitive interface, keeping your calendar updated in real-time across all devices. Enjoy secure, hassle-free payments with no hidden fees, making transactions a breeze. Expand your reach and attract more clients through our strategic marketing efforts. Delight your customers with coupons, gift cards, and flexible memberships, ensuring they keep coming back.')}      </Typography>
            </m.div>
          </Grid>
        </Grid>

        <PartnerBenefits />
        {/* <m.div variants={varFade().inUp}>
          <Typography
            component="div"
            variant="h2"
            sx={{ mb: 1, marginTop: '70px', textAlign: 'center'}}
          >
            Become
            <span style={{ color: '#7955cf', margin: '0 10px', fontFamily : 'CircularStd' }}>Partner</span>
          </Typography>

          <Typography
            component="div"
            variant="p"
            marginX={5}
            textAlign={'center'}
            sx={{ mb: 1, marginTop: '20px' }}
          >
            Transform Your Lifestyle Service Business with Timezzi
          </Typography>

          <Typography
            component="div"
            variant="h4"
            sx={{ mb: 1, margin: '1rem 0rem', color: '#7955cf', textAlign: 'center' }}
          >
            Why Partner With Us

          </Typography>

          <Grid container spacing={3} justifyContent="center">
            {WhyPartnerWithUs.map((card, index) => (
              <Grid item xs={12} sm={4} md={4} lg={4} key={card.id}>
                {mdUp ? (
                  <Box
                    sx={{
                      maxWidth: '30rem',
                      padding: '1rem',
                      textAlign: 'center',
                      height: { md: '12rem', sx: 'fit-content' },
                      '&:hover .iconBox': {
                        border: `.1rem solid ${theme.palette.primary.main}`,
                        transition: 'border 0.3s ease-in-out',
                      },
                    }}
                  >
                    <Box
                      className="iconBox"
                      color={'#7955cf'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        mb: 1,
                        width: '40px',
                        height: '40px',
                        borderRadius: '.5rem',
                        border: `0.1rem solid transparent`,
                        
                        transition: 'border 0.3s ease-in-out',
                      }}
                    >
                      <Iconify icon={card.icon} width={28} color={'#7955cf'} />
                    </Box>
                    <Typography marginBottom={1} textAlign={'center'} fontWeight={600} variant="h6">
                      {card.title}
                    </Typography>
                    <Typography variant="body2">{card.description}</Typography>
                  </Box>
                ) : (
                  <Box>
                    <Accordion
                      key={index}
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                            marginBottom: '-1rem',
                            borderRadius: '10px',
                          }}
                        >
                          <Iconify sx={{ mb: 2 }} icon={card.icon} width={28} color={'#7955cf'} />
                          <Typography textAlign={'center'} fontWeight={500} variant="subtitle1">
                            {card.title}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Typography textAlign={'center'}>{card.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          <PartnerBenefits />
        </m.div> */}


      </Container>
    </>
  );
}

BecomePartner.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};
