import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { admin_paths, partner_paths } from 'src/config-global';

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const router = useRouter();
  const { user } = useMockedUser();
  const { method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const redirectpath = useCallback(() => {
    if (user?.user_type === 2) {
      return partner_paths;
    } else {
      return admin_paths;
    }
  }, [user]);

  const check = useCallback(() => {
    if (user?.email) {
      router.push(redirectpath());
    } else {
      setChecked(true);
    }
  }, [user, method, router]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
