import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const productsApi = createApi({
  reducerPath: 'products',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Products', 'Sizes'],
  endpoints: (builder) => ({
    getAllProduct: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/inventories?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['Products'],
    }),

    getInventoryProductsById: builder.query({
      query: (id) => `/inventories/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['Products'],
    }),

    getAllSize: builder.query({
      query: () => `/size/all`,
      transformResponse: (res) => res.body,
      providesTags: ['Sizes'],
    }),

    addNewSizeType: builder.mutation({
      query: (newProduct) => ({
        url: `/size/type`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newProduct,
      }),
      invalidatesTags: ['Sizes'],
    }),
   
    addNewSizeValue: builder.mutation({
      query: (newProduct) => ({
        url: `/size/value`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newProduct,
      }),
      invalidatesTags: ['Sizes'],
    }),

    addNewProduct: builder.mutation({
      query: (newProduct) => ({
        url: `/inventories`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newProduct,
      }),
      invalidatesTags: ['Products'],
    }),

    addNewVariant: builder.mutation({
      query: ({ product_id, updatedData }) => ({
        url: `/inventories/${product_id}/variant`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Products'],
    }),

    updateProduct: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/inventories/${_id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Products'],
    }),

    updateVariant: builder.mutation({
      query: ({ product_id, variant_id, updatedData }) => ({
        url: `/inventories/${product_id}/variant/${variant_id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Products'],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetAllProductQuery,
  useGetInventoryProductsByIdQuery,
  useAddNewProductMutation,
  useUpdateVariantMutation,
  useUpdateProductMutation,
  useGetAllSizeQuery,
  useDeleteProductMutation,
  useAddNewVariantMutation,
  useAddNewSizeTypeMutation,
  useAddNewSizeValueMutation

} = productsApi;
