import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useCallback, useState } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { SettingsContext } from './settings-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'settings';

export function SettingsProvider({ children, defaultSettings }) {
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, defaultSettings);
  const [openDrawer, setOpenDrawer] = useState(false);
  const pathname = window.location.pathname;
  
  const isArabic = localStorageGetItem('i18nextLng') === 'ar';
  const isUrdu = localStorageGetItem('i18nextLng') === 'ur';

  useEffect(() => {
    if (isArabic || isUrdu) {
      onChangeDirectionByLang('ar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);
  

 // Direction by lang
 const onChangeDirectionByLang = useCallback(
  (lang) => {
    update('themeDirection', (lang === 'ar' || lang === 'ur') ? 'rtl' : 'ltr');
  },
  [update]
);

  // // Direction by lang
  // const onChangeDirectionByLang = useCallback(
  //   (lang) => {
  //     if (lang === 'ar' && pathname === '/') {
  //       update('themeDirection', 'ltr');
  //     } else if (lang === 'en') {
  //       update('themeDirection', 'ltr');
  //     } else {
  //       update('themeDirection', 'rtl');
  //     }
  //   },
  //   [update, pathname]
  // );

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(state, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      // Direction
      onChangeDirectionByLang,
      // Reset
      canReset,
      onReset: reset,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      reset,
      update,
      state,
      canReset,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      onChangeDirectionByLang,
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
  defaultSettings: PropTypes.object,
};
