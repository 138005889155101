import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Iconify from 'src/components/iconify';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import { Icon, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Image from 'src/components/image';
import { MotionViewport, varFade } from 'src/components/animate';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useResponsive } from 'src/hooks/use-responsive';
import PartnerGif from "./Gif/partnerwithus.gif"
import Boost from './Images/boost.png'
import idea from './Images/idea.png'
import eye from './Images/witness.png'
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';

const partnerBenefits = [
  {
    id: 7,
    title: 'Innovative Solutions',
    description:
      'Enhance productivity with our cutting edge time management tools',
    icon: 'ls:cloud',
    path: idea
  },
  {
    id: 8,
    title: 'Increased Visibility',
    description: 'Tailor the platform to fit your specific business needs.',
    icon: 'ion:eye',
    path: eye
  },
  {
    id: 9,
    title: 'Growth Opportunities',
    description:
      'Leverage our tools and resources to attract new clients and boost revenue.',
    icon: 'mdi:puzzle',
    path: Boost,
  },
];

export default function PartnerBenefits({ subheader = '', ...other }) {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const [expanded, setExpanded] = useState(false);

  const settings = useSettingsContext();



  console.log('mdUp = ', mdUp);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { currentLang, t } = useLocales();

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <>
      <Container component={MotionViewport} sx={{ marginTop: '2rem' }}>
        <Typography
          component="div"
          variant="h3"
          fontWeight={900}
          sx={{
            whiteSpace: {
              lg: 'nowrap',
              md: 'mdwrap',
              sm: 'wrap',
              xs: 'wrap',
            },
            mb: 0, margin: {
              lg: '1rem 0rem',
              md: '1rem 0rem',
              sm: '4rem 0rem',
              xs: '4rem 0rem',
            }, textAlign: 'center', textAlign: 'center'
          }}
        >

          {
            settings.themeDirection !== 'rtl' ? <>
              <span>{t('Reasons to Become Partner with')}</span>

              <span style={{ color: '#7955cf', margin: '0 10px' }}>{t('Timezzi')}</span>
            </>
              : <>
                <span style={{ color: '#7955cf', margin: '0 10px' }}>{t('Timezzi')}</span>
                <span>{t('Reasons to Become Partner with')}</span>

              </>
          }

        </Typography>




        <Grid container spacing={3} justifyContent="center">
          {partnerBenefits.map((card, index) => (
            <Grid item xs={12} sm={4} md={4} lg={4} key={card.id}>
              {mdUp ? (
                <Box
                  sx={{
                    maxWidth: '30rem',
                    padding: '1rem',
                    textAlign: 'center',
                    height: { md: '12rem', sx: 'fit-content' },
                    '&:hover .iconBox': {
                      border: `.1rem solid ${theme.palette.primary.main}`,
                      transition: 'border 0.3s ease-in-out',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 auto',
                      mb: 1,
                      borderRadius: '.5rem',
                      border: `0.1rem solid transparent`,
                      transition: 'border 0.3s ease-in-out',
                    }}
                  >
                    <img className='iconBox' style={{ borderRadius: '.5rem', padding: '.4rem' }} alt='icon' src={card?.path} width={'70rem'} />
                  </Box>
                  <Typography marginBottom={1} textAlign={'center'} fontWeight={600} variant="h6">
                    {t(card?.title)}
                  </Typography>
                  <Typography variant="body2">{t(card?.description)}</Typography>
                </Box>
              ) : (
                <Box>
                  <Accordion
                    key={index}
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                  >
                    <AccordionSummary>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto',
                          marginBottom: '-1rem',
                          borderRadius: '10px',
                        }}
                      >

                        <img className='iconBox' style={{ borderRadius: '.5rem', padding: '.4rem' }} alt='icon' src={card?.path} width={'70rem'} />  <Typography textAlign={'center'} fontWeight={600} variant="h6">
                          {t(card?.title)}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Typography textAlign={'center'}>{t(card?.description)}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

PartnerBenefits.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};
