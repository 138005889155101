import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const membersApi = createApi({
  reducerPath: 'members',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Members'],
  endpoints: (builder) => ({
    getAllMembers: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/team-members?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['Members'],
    }),

    getMembersById: builder.query({
      query: (id) => `/team-members/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['Members'],
    }),

    addNewMembers: builder.mutation({
      query: (updatedData) => ({
        url: `/team-members`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Members'],
    }),

    updateMembers: builder.mutation({
      query: (data) => (
        console.log("Data on API = ", data),
        {
          url: `/team-members/${data._id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data.updatedData,
        }
      ),
      invalidatesTags: ['Members'],
    }),
    // deleteCategory: builder.mutation({
    //   query: (data) => ({
    //     url: `/categories/${data.id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Category'],
    // }),
  }),
});

export const { useGetAllMembersQuery, useAddNewMembersMutation, useUpdateMembersMutation, useGetMembersByIdQuery } =
  membersApi;
