import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const campaignsApi = createApi({
    reducerPath: 'campaigns',
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Campaigns'],
    endpoints: (builder) => ({
        getAllCampaigns: builder.query({
            query: ({ search, pageno, limit, branch }) =>
                `/compaigns?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
            transformResponse: (res) => res.body,
            providesTags: ['Campaigns'],
        }),

        // getServiceById: builder.query({
        //   query: (id) => `/services/${id}`,
        //   transformResponse: (res) => res.body,
        //   providedTags: ['Services'],
        // }),

        addNewCampaigns: builder.mutation({
          query: (newService) => ({
            url: `/compaigns`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: newService,
          }),
          invalidatesTags: ['Campaigns'],
        }),

        updateCampaigns: builder.mutation({
          query: ({ _id, updatedData }) => ({
            url: `/compaigns/${_id}`,
            method: 'Put',
            headers: { 'Content-Type': 'application/json' },
            body: updatedData,
          }),
          invalidatesTags: ['Campaigns'],
        }),
    }),
});

export const {
    useGetAllCampaignsQuery, useAddNewCampaignsMutation, useUpdateCampaignsMutation
} = campaignsApi;
