import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";


export const FaqsApi = createApi({
    reducerPath: "faqs",
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Faq'],
    endpoints: (builder) => ({
        getAllFaqs: builder.query({
            query: () => ({
                url: `admin/faq`,
                method: "GET",
                transformResponse: (res) => res.data,
            }),
            providesTags: ['Faq'],
        }),

        addNewFaq: builder.mutation({
            query: (newFaq) => (
                {
                    url: `admin/faq?type=1`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: newFaq,
                }),
                invalidatesTags: ['Faq'],
        }),

        updateFaq: builder.mutation({
            query: (updatedFaq) => ({
                url: `admin/faq`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: updatedFaq
            }),
            invalidatesTags: ['Faq'],
        }),
        deleteFaq: builder.mutation({
            query: (updatedFaq) => ({
                url: `admin/faq`,
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: updatedFaq
            }),
            invalidatesTags: ['Faq'],
        }),
    }),
});

export const { useGetAllFaqsQuery, useAddNewFaqMutation, useUpdateFaqMutation,useDeleteFaqMutation } = FaqsApi;
