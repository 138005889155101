import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Icon, Iconify } from '@iconify/react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import tempPic from './Images/no-data2.png';
import { Button, Divider, useMediaQuery } from '@mui/material';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { CardHeader, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { t } from 'i18next';


const cardData = [
  {
    id: 1,
    title: 'Basic',
    subTitle: 'Visibility Boost Package',
    subDescription: '',
    description:
      'For those who want to increase their visibility and be easily found, this is the perfect choice for you.',
    currency: 'AED',
    price: '89',
    interval: '/ month',
    originalPrice: '99',
    discount: '10% OFF',
    color: 'gray',
  },
  {
    id: 2,
    title: 'Standard',
    subTitle: 'Growth Support Package',
    subDescription: '+ 25 AED per additional user',
    description:
      'Already have a payment solution in place but need a booking system that keeps you organized and supports your growth? Then this is the perfect option for you.',
    currency: 'AED',
    price: '249',
    interval: '/ month',
    originalPrice: '499',
    discount: '50% OFF',
    color: 'black',
  },
  {
    id: 3,
    title: 'Pro',
    subTitle: 'All-in-One Solution Package',
    subDescription: 'Per online transaction',
    description:
      'Are you looking for a comprehensive solution with flexible payment options? Then this is an excellent option for you.',
    currency: 'AED',
    price: '3.8% + 0.70',
    interval: '',
    originalPrice: '4.4% + 1',
    discount: 'Special Offer',
    color: 'gray',
  },
];

export default function CustomHomePricing() {
  const theme = useTheme();

  const { t } = useLocales();

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const carousel = useCarousel({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  return (
    <>
      <Container
        component={MotionViewport}
        fluid
        sx={{
          padding: '10px',
          margin: '5rem 0',
          mx: 'auto',
        }}
      >
        <m.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            component="div"
            variant="h2"
            sx={{ mb: 5, marginTop: '40px', textAlign: 'center' }}
          >
            {t('Our Pricing')}
            <span style={{ color: '#7955cf', margin: '0 10px' }}>{t('Plan')}</span>
          </Typography>

          <Typography
            component="div"
            variant="p"
            sx={{ mb: 5, marginTop: '10px', textAlign: 'center' }}
          >
            {t('Choose the package that best fits your business goals, budget, and operational needs based on the descriptions provided.')}
          </Typography>

          <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {!isLgUp ? (
              <CardHeader
                action={<CarouselArrows onNext={carousel.onNext} onPrev={carousel.onPrev} />}
              />
            ) : (
              ''
            )}

            <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
              {cardData.map((card, index) => (
                <CarousalItem key={card.id} card={card} index={index} theme={theme} />
              ))}
            </Carousel>
          </Card>

          <Typography
            component="div"
            variant="p"
            marginX={5}
            textAlign={'center'}
            sx={{ mb: 0.1, marginTop: '2rem' }}
          >
            {t('Yalla pre-sign now to take advantage of our exclusive offer and become a Partner today. This offer is valid only until the end of 2024.')}
          </Typography>
          <Typography
            component="div"
            variant="p"
            marginX={5}
            textAlign={'center'}
            sx={{ mb: 1, marginTop: '.5rem' }}
          >
            {t('Don`t miss the chance to join now and avoid the price increase.')}
          </Typography>

        </m.div>
      </Container>
    </>
  );
}

function CarousalItem({ card, index, theme }) {
  return (
    <Box
      key={card.id}
      sx={{
        backgroundColor: index == 1 && '#7955cf',
        color: index == 1 && theme.palette.primary.lighter,
        borderRadius: '1rem',
        border: `1px solid #7955cf`,
        margin: 2,
        height: {
          lg: '25rem',
          md: '28rem',
          sm: '26rem',
          xs: '25rem',
        },
        padding: '10px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          direction : 'ltr',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1.5rem',
          paddingBottom: '.5rem',
          gap: 1,
          position: 'relative',
        }}
      >
        <Box sx={{
          height: {
            lg: '11rem',
            md: '12rem',
            sm: '12rem',
            xs: '11rem',
          }
        }}>
          <Box display={'flex'} flexDirection="column" alignItems={'start'} gap={1}>
            <Typography variant="h4">{t(card?.title)}</Typography>
            <Typography> {t(card?.subTitle)} </Typography>
            <Divider
              variant="d"
              sx={{
                borderColor: theme.palette.primary.light,
              }}
            />
          </Box>
          <Typography variant="subtitle1" sx={{ color: 'red' }}>
            {t(card.discount)}
          </Typography>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography variant="h5" sx={{ textDecoration: 'line-through', color: card.color }}>
              {t(card?.originalPrice)} {t(card.currency)}
            </Typography>
            <Typography variant="h6" sx={{ color: card.color }}>
              {index === 2 ? '' : `/ ${t('month')}`}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t(card?.price)} {t('AED')}
            </Typography>
            <Typography variant="h6">{index === 2 ? '' : `/ ${t('month')}`}</Typography>
          </Box>
          <Typography variant="subtitle2">
            {t(card?.subDescription) ? t(card?.subDescription) : ''}
          </Typography>
        </Box>

    

        <Button
          style={{
            marginTop: '.5rem',
            backgroundColor:
              index === 1
                ? theme.palette.primary.lighter
                : index === 2
                  ? theme.palette.primary.main
                  : '',
            color:
              index === 1
                ? 'black'
                : index === 2
                  ? theme.palette.primary.lighter
                  : '',
          }}
          color={'inherit'}
          variant="contained"
        >
          {t('Available Soon')}
        </Button>
        <Typography sx={{ marginTop: '1rem', marginBottom: 1 }} variant="body2">
        {t(card.description)}
        </Typography>
      </Box>
    </Box>
  );
}

CarousalItem.propTypes = {
  item: PropTypes.object,
};
