import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const subscriptionPlansApi = createApi({
  reducerPath: 'subscriptions-plans',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['SubscriptionsPlans'],
  endpoints: (builder) => ({
    getAllSubscriptionsPlans: builder.query({
      query: ({ search, pageno, limit }) =>
        `/subscription-plan?search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['SubscriptionsPlans'],
    }),

    // getServicesForDropDown: builder.query({
    //   query: () => `service/all`,
    //   transformResponse: (res) => res.body,
    // }),

    addNewSubscriptionPlan: builder.mutation({
      query: (data) => ({
        url: `/subscription-plan`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['SubscriptionsPlans'],
    }),

    // getServicebySearch: builder.query({
    //   query: () => `/service/all`,
    //   transformResponse: (res) => res.body,
    // }),


    // updateOfferType: builder.mutation({
    //   query: ({ _id, updatedData }) => ({
    //     url: `/services/${_id}/offer`,
    //     method: 'Put',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: updatedData,
    //   }),
    //   invalidatesTags: ['SubscriptionsPlans'],
    // }),

    // updateService: builder.mutation({
    //   query: ({ _id, updatedData }) => ({
    //     url: `/services/${_id}`,
    //     method: 'Put',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: updatedData,
    //   }),
    //   invalidatesTags: ['Services'],
    // }),
  }),
});

export const {
  useGetAllSubscriptionsPlansQuery,
  useAddNewSubscriptionPlanMutation
} = subscriptionPlansApi;
