import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Typography } from '@mui/material';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import Logo from 'src/components/logo';
// ----------------------------------------------------------------------

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import { useTheme } from '@emotion/react';
import { t } from 'i18next';
import { useLocales } from 'src/locales';

export default function UserNewSignUpForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [addNewPreBusiness] = useAddNewPreBusinessMutation();



  const locales = useLocales();

  console.log("locales = ", locales)

  const isCustomer = window.location.pathname === '/';





  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    // ...(!isCustomer && {
    //   businessName: Yup.string()
    //     .matches(/^[A-Za-z\s]+$/, 'Business name must contain only alphabets')
    //     .required('Business name is required'),
    // }),

    businessName: Yup.string()
      .matches(/[A-Za-z]+/, 'Business name must contain at least one alphabet character')
      .required('Business name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log('Watch URL--------', values);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        ...data,
        // user_type: isCustomer ? 1 : 2,
        user_type: 2,
      };
      let response = await addNewPreBusiness(newData);
      if (response.error) {
        enqueueSnackbar(response.error.data.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Request Send Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      component={MotionViewport}
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 600, height: 'fit-content' },
      }}
    >
      <m.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Box
          onClick={onClose}
          sx={{
            marginTop: '.5rem',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: 1,
            cursor: 'pointer',
          }}
        >
          {locales?.currentLang?.label === 'Arabic' ? <Iconify icon="solar:alt-arrow-right-outline" /> : <Iconify icon="solar:alt-arrow-left-outline" />} <Typography>{t('Back to Home')}</Typography>
        </Box>
        <Box
          sx={{ width: '100%', marginTop: '2rem' }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Logo />
            {/* <Typography variant="h3" fontWeight={'bolder'}>
              Sign Up as {isCustomer?'Customer':'Business'}
            </Typography> */}
            <Typography variant="h3" fontWeight={'bolder'}>
              {t('Sign Up as Partner')}
            </Typography>
          </Box>
          <FormProvider style={{ width: '100%' }} methods={methods} onSubmit={onSubmit}>
            <DialogContent sx={{ width: '100%', marginTop: '2rem', paddingTop: "10px" }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(1, 1fr)',
                  lg: 'repeat(1, 1fr)',
                }}
              >
                <RHFTextField name="name" label="Name" />
                {/* {!isCustomer && <RHFTextField name="businessName" label="Company Name" />} */}
                <RHFTextField name="email" label="Email" />
                <RHFTextField name="businessName" label="Company Name" />
              </Box>
            </DialogContent>

            <DialogActions>


              <LoadingButton
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': { backgroundColor: theme.palette.secondary.main },
                }}
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {t('Signup')}
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </Box>
      </m.div>
    </Dialog>
  );
}

UserNewSignUpForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
