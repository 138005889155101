import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';
import { method } from 'lodash';

export const agentsApi = createApi({
  reducerPath: 'agents',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Agents'],
  endpoints: (builder) => ({
    getAllAgents: builder.query({
      query: ({ pageno, limit, search }) =>
        `/auth/agents?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['Agents'],
    }),

    getAllAgentsDrowdown: builder.query({
      query: () => `auth/agents`,
      transformResponse: (res) => res.body,
      providesTags: ['Agents'],
    }),

    addNewAgent: builder.mutation({
      query: (newAgent) => ({
        url: `/auth/agents`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newAgent,
      }),
      invalidatesTags: ['Agents'],
    }),

    updateAgent: builder.mutation({
      query: (data) => (
        console.log('Data on API = ', data),
        {
          url: `auth/updateagent/${data._id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data.updatedData,
        }
      ),
      invalidatesTags: ['Agents'],
    }),
  }),
});

export const {
  useGetAllAgentsQuery,
  useAddNewAgentMutation,
  useGetAllAgentsDrowdownQuery,
  useUpdateAgentMutation,
} = agentsApi;
