import AboutHero from '../about-hero';
import AboutWhat from '../about-what';
import AboutTeam from '../about-team';
import AboutVision from '../about-vision';
import AboutTestimonials from '../about-testimonials';

// ----------------------------------------------------------------------

export default function AboutView() {
  return (
    <>
     <AboutHero data={{title:"Who",list:['We','are?']}} />
     <AboutWhat data={{title:"What is Timezzi",content:''}}/>
      {/* <AboutVision /> */}

      {/* <AboutTeam /> */}

      <AboutTestimonials />
    </>
  );
}
