import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Iconify from 'src/components/iconify';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import { Divider, Icon } from '@mui/material';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useResponsive } from 'src/hooks/use-responsive';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import Image from 'src/components/image';
import { alpha, useTheme } from '@mui/material/styles';
import { useLocales } from 'src/locales';

import sponsorGif from "./Gif/becomesponsor.gif"

import { useRef } from 'react';
import { useEffect } from 'react';
import videosrc1 from "./Videos/Become_Sponsor.mp4"

const partnerBenefits = [
  {
    id: 7,
    title: 'UNMATCHED',
    subtitle: 'VISIBILITY',
    description:
      'Imagine your brand prominently featured in our marketing efforts, reaching a diverse and engaged audience.',
    icon: 'flat-ui:support',
  },
  {
    id: 8,
    title: 'IMPACTFUL',
    subtitle: 'ENGAGEMENT',
    description:
      'Picture being part of a journey that revolutionizes how lifestyle services are managed and experienced.',
    icon: 'noto:wrench',
  },
  {
    id: 9,
    title: 'STRATEGIC',
    subtitle: 'PARTNERSHIP',
    description:
      'Envision a collaboration that not only enhances your brand`s reputation but also aligns with a visionary future in the UAE.',
    icon: 'noto:seedling',
  },
];

export default function BecomeSponsor({ subheader = '', ...other }) {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const [expanded, setExpanded] = useState(false);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
  }, [])

  const { t } = useLocales();
  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <>

      <Container component={MotionViewport} sx={{
        height: '100%',
        py: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'unset' },
      }}>

        <Grid container columnSpacing={{ md: 3 }} flexDirection={'row-reverse'} alignItems="center" >

          <Grid xs={12} md={5} lg={5} alignItems="center" justifyContent={'center'} sx={{ pr: { md: 7 } }}>

            {/* <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src={sponsorGif}
                ratio="1/1"
                sx={{
                  marginLeft: {
                    lg: 0,
                    md: '1rem',
                  },
                  width: {
                    md: '100%',
                    sm: '70%',
                    xs: '100%',
                  }, borderRadius: 3
                }}
              />
            </m.div> */}

            <m.div variants={varFade().inUp}>

              <video
                style={{
                  width: '100%',
                  margin: '.1rem',
                  borderRadius: '.5rem',
                  objectFit: 'cover',
                }}
                ref={videoRef}
                playsInline
                autoPlay
                disablePictureInPicture
                muted
              >
                <source
                  src={videosrc1}
                  type="video/mp4"
                />
              </video>

              {/* <video style={{
                width: '100%',
                margin: '.1rem',
                borderRadius: '.5rem',
                objectFit: 'cover'
              }} src={videosrc1} ref={videoRef} playsInline autoPlay disablePictureInPicture muted /> */}

            </m.div>

          </Grid>


          <Grid xs={12} md={7} lg={7} sx={{
            paddingRight: {
              lg: '4rem',
              md: '4rem',
              sm: '0rem',
              xs: '0rem'
            },

            marginTop: {
              lg: '0rem',
              md: '0rem',
              sm: '2rem',
              xs: '2rem'
            }
          }}>
            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="h2"
                sx={{ mb: 1, marginTop: '0px', textAlign: 'center', fontStyle: 'italic' }}
              >
                {t('Become')}
                <span style={{ color: '#7955cf', margin: '0 10px' }}>  {t('Sponsor')}</span>
              </Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
              <Typography
                component="div"
                variant="p"
                marginX={0}
                textAlign={'justify'}
                sx={{ mb: 1, marginTop: '20px', textAlign: 'center' }}
              >
                {t('Imagine being at the forefront of the lifestyle service revolution by sponsoring Timezzi. As a sponsor, you will play a pivotal role in our mission to enhance customer experiences and streamline service management.')}
              </Typography>
              <Typography
                component="div"
                variant="p"
                marginX={0}
                textAlign={'justify'}
                sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
              >
                {t('Picture your brand gaining unparalleled visibility as we reach a broad and engaged audience across the UAE. This isn`t just a sponsorship; it`s a partnership that places you at the heart of a transformative journey, one that redefines convenience and customer satisfaction in lifestyle services.')}



              </Typography>
              <Typography
                component="div"
                variant="p"
                marginX={0}
                textAlign={'justify'}
                sx={{ mb: 1, marginTop: '20px', textAlign: 'center', color: '' }}
              >
                {t('Join us and be part of a story where your brand is showcased to thousands, driving innovation and excellence in service. Together, we`ll shape the future of lifestyle services in the UAE.')}
              </Typography>
            </m.div>
          </Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="center" marginTop={'2rem'}>
          {partnerBenefits.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={card.id}>
              <Box
                sx={{
                  maxWidth: '40rem',
                  padding: '1rem',
                  textAlign: 'center',
                  height: { md: '12rem', sx: 'fit-content' },
                }}
              >
                <Typography
                  component="div"
                  variant="h4"
                  sx={{ mb: 1, whiteSpace: 'nowrap', marginTop: '0px', fontWeight: 900, textAlign: 'center', fontStyle: 'italic' }}
                >
                  {t(card?.title)}
                  <span style={{ color: '#7955cf', margin: '0 10px', fontWeight: 900 }}> {t(card?.subtitle)}</span>
                </Typography>
                <Typography sx={{ textAlign: 'left' }} variant="body2">{t(card.description)}</Typography>
              </Box>
              <Divider sx={{ borderStyle: 'line', borderWidth: '.1rem', borderColor: 'black', width: '90%' }} />
            </Grid>
          ))}
        </Grid>

      </Container>


      {/* <Container
        component={MotionViewport}
        sx={{
          height: '100vh',
          py: { xs: 10, md: 15 },
          textAlign: { xs: 'center', md: 'unset' },

        }}
      >
        <m.div variants={varFade().inUp}>
          <Typography
            component="div"
            variant="h2"
            sx={{ textAlign: 'center' }}
          >
            Become
            <span style={{ color: '#7955cf', margin: '0 10px' }}>Sponsor</span>
          </Typography>

          <Typography
            component="div"
            variant="p"
            marginX={5}
            textAlign={'center'}
            sx={{ mb: 1, marginTop: '20px' }}
          >
            Join us in the future of lifestyle services by becoming a sponsor of Timezzi. As a sponsor, you will play a vital role in our mission to enhance customer experiences and streamline service management. Partnering with Timezzi offers you the unique opportunity to reach a board and engaged audience, showcase tour brand, and be part of transformative journey in the UAE.
          </Typography>

          <Typography
            component="div"
            variant="h4"
            sx={{ mb: 1, color: '#7955cf', margin: '1rem 0rem', textAlign: 'center' }}
          >
            Benefits of Sponsorship

          </Typography>

          <Grid container spacing={3} justifyContent="center">
            {partnerBenefits.map((card, index) => (
              <Grid item xs={12} sm={4} md={4} lg={4} key={card.id}>
                {mdUp ? (
                  <Box
                    sx={{
                      maxWidth: '30rem',
                      padding: '1rem',
                      textAlign: 'center',
                      height: { md: '12rem', sx: 'fit-content' },
                      '&:hover .iconBox': {
                        border: `.1rem solid ${theme.palette.primary.main}`,
                        transition: 'border 0.3s ease-in-out',
                      },
                    }}
                  >
                    <Box
                      className="iconBox"
                      color={'#7955cf'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        mb: 1,
                        width: '40px',
                        height: '40px',
                        borderRadius: '.5rem',
                        border: `0.1rem solid transparent`,

                        transition: 'border 0.3s ease-in-out',
                      }}
                    >
                      <Iconify icon={card.icon} width={28} color={'#7955cf'} />
                    </Box>
                    <Typography marginBottom={1} textAlign={'center'} fontWeight={600} variant="h6">
                      {card.title}
                    </Typography>
                    <Typography variant="body2">{card.description}</Typography>
                  </Box>
                ) : (
                  <Box>
                    <Accordion
                      key={index}
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                            marginBottom: '-1rem',
                            borderRadius: '10px',
                          }}
                        >
                          <Iconify sx={{ mb: 2 }} icon={card.icon} width={28} color={'#7955cf'} />
                          <Typography textAlign={'center'} fontWeight={600} variant="h6">
                            {card.title}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Typography textAlign={'center'}>{card.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>

          <Typography
            component="div"
            variant="p"
            marginX={5}
            textAlign={'center'}
            sx={{ mb: 1, marginTop: '2rem' }}
          >
            We're excited about the opportunity to partner with you! If you're interested in sponsoring Timezzi, please reach out to us at <strong>info@timezzi.com</strong>.

            We'll get back to you soon to explore the exciting possibilities together.  </Typography>
        </m.div>
      </Container > */}
    </>
  );
}

BecomeSponsor.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};
